<template>
  <div
    :id="`msg_${data.msgId}`"
    class="co-flex-col"
  >
    <!-- 信息时间 -->
    <div
      v-if="data.hintTime"
      class="co-flex-row co-justify-center co-items-center co-w-full"
    >
      <div class="co-txt-hint co-mb4">
        {{ data.hintTime }}
      </div>
    </div>

    <template v-if="reversed">
      <!-- 信息内容从右向左布局 -->
      <div class="co-flex-row co-justify-end co-w-full">
        <div class="co-flex-col co-mb6">
          <div
            v-if="showUsername"
            class="co-flex-row co-justify-end"
          >
            <div class="co-text-xs co-txt-line1 co-text-gray-500 co-mb2">
              {{ data.from.username }}
            </div>
          </div>

          <div :class="['co-flex-row', 'co-p6', 'co-round', bkColor, wdShadow]">
            <div :class="['co-text-md', frColor]">
              {{ data.content }}
            </div>
          </div>
        </div>

        <el-image
          class="co-icon48 co-round2 co-ml6"
          :src="data.from.avatar"
          fit="cover"
        />
      </div>
    </template>
    <template v-else>
      <!-- 信息内容从左向右布局 -->
      <div class="co-flex-row co-w-full">
        <el-image
          class="co-icon48 co-round2 co-mr6"
          :src="data.from.avatar"
          fit="cover"
        />
        <div class="co-flex-col co-mb6">
          <div
            v-if="showUsername"
            class="co-flex-row"
          >
            <div class="co-text-xs co-txt-line1 co-text-gray-500 co-mb2">
              {{ data.from.username }}
            </div>
          </div>

          <div :class="['co-flex-row', 'co-p6', 'co-round', bkColor, wdShadow]">
            <div :class="['co-text-md', frColor]">
              {{ data.content }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import app from '@/api/co.app'
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {}
        }
      },
      backColor: {
        type: String,
        default: 'white'
      },
      foreColor: {
        type: String,
        default: 'gray900'
      },
      shadow: {
        type: String,
        default: 'md'
      },
      showUsername: {
        type: Boolean,
        default: false
      },
      reversed: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      frColor () {
        return app.style.fore.colors[this.foreColor] || app.style.fore.colors.gray900
      },
      bkColor () {
        return app.style.back.colors[this.backColor] || app.style.back.colors.white
      },
      wdShadow () {
        return app.style.shadows[this.shadow] || ''
      }
    },
  }
</script>
